import { merge } from "lodash"
import defaultConfig from "@common/config/edita.config.js"
import {
  INTERNATIONAL_TREATY_CONSOLIDATED,
  EU_STATUTE_CONSOLIDATED,
  GOVERNMENT_BILL,
  STATUTE_AMENDMENT,
  STATUTE_CONSOLIDATED,
} from "@/config/material-type"

const SERVICE_CODE = "tukes"

const CONFIG = merge({}, defaultConfig, {
  serviceCode: SERVICE_CODE,
  languages: [
    "fi",
    "sv",
  ],
  // Configurations for DS components' content
  // Use Vue component name for easier distinction.
  DocumentListItem: { DocumentActions: { orientation: "horizontal" } },
  FrontPage: {
    auth: { blocks: [] },
    guest: {
      blocks: [
        {
          type: "latest",
          materialType: [STATUTE_AMENDMENT.materialType],
          heading: "heading.statuteAmendment.frontpage",
          count: 6,
          view: "list",
          orientation: "vertical",
        },
        {
          type: "latest",
          onlyLanguages: ["fi"], // Do not show this in other languages
          materialType: [GOVERNMENT_BILL.materialType],
          heading: "heading.governmentBill.index",
          count: 7,
          view: "list",
          orientation: "vertical",
        },
      ],
    },
  },
  PageHeader: {
    requireAuthentication: false,
    extraNav: [
      {
        meta: { title: "label.keywords" },
        name: "keywords",
      },
      {
        meta: { title: "label.instructions" },
        name: "module-other-document.documentView",
        navParams: { documentKey: "ohjeet" },
      },
    ],
  },
  PageFooter: {
    requireAuthentication: false,
    PageFooterBranding: {
      url: "https://www.tukes.fi",
      name: "tukes",
      nameFull: "Tukes",
      target: "_blank",
      coop: true,
    },
    PageFooterLinks: [
      {
        label: "label.contactInformation",
        to: {
          name: "module-other-document.documentView",
          params: { documentKey: "yhteystiedot" },
        },
      },
      {
        label: "label.termsOfUse",
        to: {
          name: "module-other-document.documentView",
          params: { documentKey: "kayttoehdot" },
        },
      },
      {
        label: "label.accessibility",
        to: {
          name: "module-other-document.documentView",
          params: { documentKey: "saavutettavuusseloste" },
        },
      },
    ],
  },
  SearchSuggest: [
    {
      type: "keyword",
      listTitle: "label.keyword",
      limit: 5,
      showDocumentCount: true,
    },
    {
      type: "document",
      listTitle: "label.statute",
      materialType: [
        INTERNATIONAL_TREATY_CONSOLIDATED.materialType,
        EU_STATUTE_CONSOLIDATED.materialType,
        STATUTE_CONSOLIDATED.materialType,
      ],
      limit: 5,
      showDocumentCount: false,
    },
  ],
})

export default CONFIG
