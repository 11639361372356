import { render, staticRenderFns } from "./CommonNamesAndAbbreviationsWrapper.vue?vue&type=template&id=7e3e5b2c&scoped=true&lang=html&"
import script from "./CommonNamesAndAbbreviationsWrapper.vue?vue&type=script&lang=javascript&"
export * from "./CommonNamesAndAbbreviationsWrapper.vue?vue&type=script&lang=javascript&"
import style0 from "./CommonNamesAndAbbreviationsWrapper.vue?vue&type=style&index=0&id=7e3e5b2c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../modules/tukes/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e3e5b2c",
  null
  
)

export default component.exports