
import { GOVERNMENT_BILL, LEGISLATION } from "./material-group"

export default [
  {
    id: "all",
    label: "label.all",
    childGroups: [
      LEGISLATION.id,
      GOVERNMENT_BILL.id,
    ],
    selected: true,
  },
  LEGISLATION,
  GOVERNMENT_BILL,
]
