/* eslint-disable max-len */
// If necessary, translations can be exploded to separate files:
// - Splitting by language or
// - Splitting by context (documents, users, common service, etc)
import { merge } from "lodash"
import { common } from "@common/config/i18n"

export default merge({}, common, {
  fi: {
    // Customize translations here
    "service.name": "Tukes",
    "description.euStatuteConsolidated": "",
    "description.governmentBill": "<b>Hallituksen esitykset</b> -osio sisältää Tukes-palvelusta löytyvien lakien muutoksiin ja Lakipalvelun teemoihin liittyvät uusien lakien hallituksen esitykset. Vanhemmat hallituksen esitykset löytyvät kronologisessa järjestyksessä vuosihakemistosta.",
    "description.internationalTreatyConsolidated": "",
    "description.statuteConsolidated": "<b>Lainsäädäntö</b>-osio sisältää Tukesin valitsemia säädöksiä ja määräyksiä, jotka on jaoteltu aihealueittain. Säädökset on asiasanoitettu.",
  },
  sv: {
    // Customize translations here
    "service.name": "Tukes",
    "description.euStatuteConsolidated": "",
    "description.governmentBill": "<b>Regeringens propositioner</b> -sektionen innehåller regeringens propositioner för nya lagar som hittas i Tukes-tjänsten eller hänför sig till lagtjänstens teman.",
    "description.internationalTreatyConsolidated": "",
    "description.statuteConsolidated": "<b>Lagstiftning</b>-sektionen innehåller författingar och bestämmelser valda av Tukes, och som är indelade i ämnesområden. Författningarna är försedda med ämnesord.",
  },
})
