import { render, staticRenderFns } from "./LoginWrapper.vue?vue&type=template&id=374fa272&lang=html&"
import script from "./LoginWrapper.vue?vue&type=script&lang=js&"
export * from "./LoginWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../modules/tukes/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports