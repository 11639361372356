// Use commonly defined routes, and customize them here if needed.
// Import section functions
import {
  STATUTE_CONSOLIDATED as STATUTE_CONSOLIDATED_ROUTE,
  EU_STATUTE_CONSOLIDATED as EU_STATUTE_CONSOLIDATED_ROUTE,
  INTERNATIONAL_TREATY_CONSOLIDATED as INTERNATIONAL_TREATY_CONSOLIDATED_ROUTE,
  GOVERNMENT_BILL as GOVERNMENT_BILL_ROUTE,
} from "@common/router/routes"
import { makeGroupRoute } from "@common/router/utils"

// Use customized material configuration for routes
// Do not change material configuration here
import {
  STATUTE_CONSOLIDATED,
  EU_STATUTE_CONSOLIDATED,
  GOVERNMENT_BILL,
  INTERNATIONAL_TREATY_CONSOLIDATED,
} from "@/config/material-type"

const euStatuteConsolidated = EU_STATUTE_CONSOLIDATED_ROUTE(EU_STATUTE_CONSOLIDATED)
const governmentBill = GOVERNMENT_BILL_ROUTE(GOVERNMENT_BILL)
const internationalTreatyConsolidated = INTERNATIONAL_TREATY_CONSOLIDATED_ROUTE(INTERNATIONAL_TREATY_CONSOLIDATED)
const statuteConsolidated = STATUTE_CONSOLIDATED_ROUTE(STATUTE_CONSOLIDATED, { commonNamesAndAbbreviations: true })

const statuteGroup = makeGroupRoute([
  statuteConsolidated, // First item determines navigation item (label)
  euStatuteConsolidated,
  internationalTreatyConsolidated,
])

// Ordering here determines navigation ordering. Do NOT sort them alphabetically
// like everywhere else!
export default [
  statuteGroup,
  governmentBill,
]
