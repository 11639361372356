import { merge } from "lodash"
import * as defaultMaterial from "@common/config/material-type"

const STATUTE_AMENDMENT_CUSTOM = {
  configuration: {
    actions: {
      edilexReferenceCard: false,
      edilexStatuteAmendment: false,
      finlexReferenceCard: true,
    },
    sidebar: { updateInfo: defaultMaterial.STATUTE_CONSOLIDATED.materialType },
  },
}

const STATUTE_CONSOLIDATED_CUSTOM = {
  configuration: {
    actions: {
      edilexReferenceCard: false,
      edilexStatuteAmendment: false,
      finlexReferenceCard: true,
    },
  },
}

const GOVERNMENT_BILL_CUSTOM = {
  configuration: {
    actions: {
      parliamentReferenceCard: true,
      parliamentReferenceCardFiPdf: true,
      parliamentReferenceCardSvPdf: true,
    },
  },
}
const EU_STATUTE_CONSOLIDATED_CUSTOM = { configuration: { actions: { languages: true } } }
// Combine custom configuration to default
const EU_STATUTE_CONSOLIDATED = merge(
  {},
  defaultMaterial.EU_STATUTE_CONSOLIDATED,
  EU_STATUTE_CONSOLIDATED_CUSTOM
)
const GOVERNMENT_BILL = merge(
  {},
  defaultMaterial.GOVERNMENT_BILL,
  GOVERNMENT_BILL_CUSTOM
)
const INTERNATIONAL_TREATY_CONSOLIDATED = defaultMaterial.INTERNATIONAL_TREATY_CONSOLIDATED
const MODULE_OTHER_DOCUMENT = defaultMaterial.MODULE_OTHER_DOCUMENT
const STATUTE_AMENDMENT = merge(
  {},
  defaultMaterial.STATUTE_AMENDMENT,
  STATUTE_AMENDMENT_CUSTOM
)
const STATUTE_CONSOLIDATED = merge(
  {},
  defaultMaterial.STATUTE_CONSOLIDATED,
  STATUTE_CONSOLIDATED_CUSTOM
)

const ALL = {
  [EU_STATUTE_CONSOLIDATED.materialType]: EU_STATUTE_CONSOLIDATED,
  [GOVERNMENT_BILL.materialType]: GOVERNMENT_BILL,
  [INTERNATIONAL_TREATY_CONSOLIDATED.materialType]: INTERNATIONAL_TREATY_CONSOLIDATED,
  [MODULE_OTHER_DOCUMENT.materialType]: MODULE_OTHER_DOCUMENT,
  [STATUTE_AMENDMENT.materialType]: STATUTE_AMENDMENT,
  [STATUTE_CONSOLIDATED.materialType]: STATUTE_CONSOLIDATED,
}

export {
  ALL,
  EU_STATUTE_CONSOLIDATED,
  GOVERNMENT_BILL,
  INTERNATIONAL_TREATY_CONSOLIDATED,
  MODULE_OTHER_DOCUMENT,
  STATUTE_AMENDMENT,
  STATUTE_CONSOLIDATED,
}
