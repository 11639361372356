
import {
  EU_STATUTE_CONSOLIDATED,
  INTERNATIONAL_TREATY_CONSOLIDATED,
  GOVERNMENT_BILL as GOVERNMENT_BILL_MATERIAL,
  STATUTE_CONSOLIDATED,
} from "./material-type"

const GOVERNMENT_BILL = {
  id: "governmentBills",
  label: "materialGroup.governmentBills",
  materialTypes: [GOVERNMENT_BILL_MATERIAL.materialType],
  selected: true,
}

const LEGISLATION = {
  id: "legislation",
  label: "materialGroup.legislation",
  materialTypes: [
    EU_STATUTE_CONSOLIDATED.materialType,
    INTERNATIONAL_TREATY_CONSOLIDATED.materialType,
    STATUTE_CONSOLIDATED.materialType,
  ],
  selected: true,
}

export {
  GOVERNMENT_BILL,
  LEGISLATION,
}
